import { setRefreshToken } from 'shared/reducers/account'

export const logout = () => {
  return async (dispatch) => {
    console.log('logging out user')

    dispatch(setRefreshToken(null))
    dispatch({ type: 'RESET' }) // Reset entire store to initial state
  }
}
