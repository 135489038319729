import Bugsnag from 'shared/services/Bugsnag'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { directUpload, imageInterface } from 'shared/utils/directUpload'
import { GalleryComponent } from 'shared/components/Gallery/GalleryComponent'
import { Header } from 'web/pages/OrderRequest/styles'
import { InfoModal } from 'web/components/InfoModal'
import { styler } from 'shared/utils/styler'
import { useImagesContext } from 'shared/contexts/ImagesContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const MAX_IMAGES = 5

export const InspirationPhotos = ({ onChange }) => {
  const { width } = useWindowDimensions()
  const { images, addImage, removeImage, replaceImage } = useImagesContext()

  const [showModal, setShowModal] = useState(false)

  const uploadImage = useCallback(
    async (image) => {
      const tempId = uuidv4()
      const url = URL.createObjectURL(image)

      addImage({ id: tempId, url, loading: true })

      try {
        const fileInterface = await imageInterface(image)
        const directUploadJson = await directUpload(fileInterface)
        const { signedId, key } = directUploadJson

        replaceImage(tempId, { id: tempId, key, url, signedId })
      } catch (e) {
        console.error('error picking image', e)
        Bugsnag.notify(e)
        removeImage(tempId)
      }
    },
    [addImage, removeImage, replaceImage],
  )

  const uploadImages = useCallback(
    (upload) => {
      let notify = false
      const currentImageCount = images?.length ?? 0

      if (currentImageCount + upload?.length > MAX_IMAGES) {
        upload = upload.slice(0, MAX_IMAGES - currentImageCount)
        notify = true
      }

      upload.forEach((image) => uploadImage(image))
      if (notify) alert(`You may upload a maximum of ${MAX_IMAGES} images.`)
    },
    [images, uploadImage],
  )

  const onDrop = useCallback(
    (files) => {
      document.activeElement.blur()
      const acceptedFiles = files.filter((f) => f.type.match(/^image\//))
      if (acceptedFiles.length) uploadImages(acceptedFiles)
    },
    [uploadImages],
  )

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  const instagramTroubleUpload = () => {
    if (navigator.userAgent.match(/instagram/i)) {
      return (
        <>
          {showModal && <InfoModal onClose={() => setShowModal(false)} />}

          <TextContainer>
            <TroubleText>Having trouble uploading photos?</TroubleText>
            <LinkText onClick={() => setShowModal(!showModal)}>Click here</LinkText>
          </TextContainer>
        </>
      )
    } else {
      return <div />
    }
  }

  // Update parent component with images
  useEffect(() => {
    onChange(images)
  }, [images, onChange])

  return (
    <>
      <Header>Do you have picture(s) for inspiration?</Header>
      <GalleryContainer {...getRootProps()}>
        {isDragActive && <Highlight />}
        <GalleryComponent
          galleryWidth={width - 40 > 500 ? 500 : width - 40}
          addImages={uploadImages}
          itemProps={{ removeImage }}
          maxPlaceholders={5}
          data={images.map((image) => ({
            id: image.id,
            url: image.thumbnailUrl || image.url,
            loading: image.loading,
          }))}
        />
      </GalleryContainer>

      {instagramTroubleUpload()}
    </>
  )
}

const GalleryContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  outline: 0,
})

const Highlight = styler(Cross.View)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  border: `4px solid ${Colors.blue}`,
})

const TroubleText = styled.text({
  fontWeight: 'bold',
  color: Colors.black,
})

const LinkText = styled.text({
  fontWeight: 'bold',
  color: Colors.aqua,
  fontSize: 22,
  textDecoration: 'underline',
  textUnderlineOffset: 2,
  textAlign: 'left',
  cursor: 'pointer',
})

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: 30,
})
